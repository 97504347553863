<template>
  <div>
    <div v-if="isshowgrid">
      <va-card title="Food List">
        <div class="row">
          <div class="flex md6 mt-2">
            <va-input class="va-input-search-container"
              :value="term"
              placeholder="search"
              @input="search"
              removable
            >
              <va-icon name="fa fa-search" slot="prepend" />
            </va-input>
          </div>
          <div class="flex md6 mt-2 text-right">
            <va-button
              icon="fa fa-plus"
              class="my-0"
              @click.prevent="add()">
              Create
            </va-button>
          </div>
        </div>
        <va-data-table
          :fields="fields"
          :data="filteredData"
          :per-page="parseInt(perPage)"
          style="max-height: 90vh;">
          <template slot="actions" slot-scope="props">
            <va-button
              flat
              small
              color="gray"
              icon="fa fa-pencil"
              @click="edit(props.rowData)"
              class="ma-0">
            </va-button>
          </template>
          <template slot="action" slot-scope="props">
            <va-button
              flat
              small
              color="red"
              icon="fa fa-remove"
              @click="remove(props.rowData)"
              class="ma-0">
            </va-button>
          </template>

        </va-data-table>
        <va-modal
          v-model="showRemoveModal"
          title="Delete Food"
          size='small'
          :message="msg"
          okText="Confirm"
          cancelText="Cancel"
          @ok="deleteFood(entity)"
          @cancel="cancel()">
        </va-modal>
      </va-card>
    </div>
    <div v-if="isshowForm">
      <va-card :title="title">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form>
            <span class="va-form-label va-form-required-label">Food Name</span>
            <va-input
              v-model.trim="food_name"
              type="text"
              v-if="isCreate"
              maxlength=100
              placeholder="Enter Food Name"
              :error="!!FoodNameErrors.length"
              :error-messages="FoodNameErrors"
            />
            <va-input
              v-if="isUpdate"
              type="text"
              v-model.trim="food_name"
              disabled
            />
            <span class="va-form-label va-form-required-label">Description</span>
            <va-input
              v-model.trim="description"
              type="text"
              v-if="isCreate || isUpdate"
              placeholder="Enter Your Description"
              :error="!!DescriptionErrors.length"
              :error-messages="DescriptionErrors"
            />
            <span class="va-form-label va-form-required-label">Category</span>
            <multiselect
              v-if="isCreate || isUpdate"
              v-model="category"
              placeholder="Select Food Category"
              :close-on-select="false"
              :clear-on-select="false"  
              :preserve-search="true"
              :hide-selected="false"
              :taggable="false"
              :multiple="true"
              :options="categoryArr"
              label="category"
              track-by="category"
            />
            <div v-if="CategoryErrors.length > 0"  class="errors">{{CategoryErrors[0]}}</div>
            <span class="va-form-label va-form-required-label">Type</span>
            <va-select
              v-if="isCreate || isUpdate"
              placeholder="Select Type"
              v-model="type"
              textBy="type"
              searchable
              :options="typeArr"
              :error="!!TypeErrors.length"
              :error-messages="TypeErrors"
            />
            <span class="va-form-label va-form-required-label">Price</span>
            <va-input
              v-if="isCreate || isUpdate"
              v-model.trim="price"
              type="number"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              maxlength="6"
              placeholder="Enter Your Price"
              :error="!!PriceErrors.length"
              :error-messages="PriceErrors"
            />
            <div>
              <span v-if="isCreate" class="va-form-label va-form-required-label">Image (500 X 500)</span>
              <span v-if="isUpdate" class="va-form-label">Image (500 X 500)</span>
              <div class="row">
                <va-file-upload
                  v-if="isUpdate || isCreate"
                  type="single"
                  class="mt-2 ml-3"
                  @input="CheckFile('horizontal')"
                  v-model="image_file"
                />
                <div v-if="isUpdate">
                  <div class="scroll_overflow">
                    <div class="ml-4 mt-2" v-if="oldImage">
                      <div><img :src=image_url height="80px" width="80px" /></div>                 
                    </div>
                  </div>
                </div>
                <div
                  v-if="isCreate || isUpdate"
                  class="imagePreviewWrapper mt-1 ml-2"
                  :style="{ 'background-image': `url(${previewImage})` }">
                </div>
              </div>
            </div>
            <span class="va-form-label">Upload Video</span>
            <div>
              <input
                type="file"
                style="display: none;"
                ref="food_video"
                accept=".mp4"
                id="food_video"
                class="mt-2"
                v-on:change="validateVideo($event,'food')"
              />              
            </div>
            <va-button
              type="submit"
              class="mt-2 ml-1"
              @click.prevent="$refs.food_video.click()">
              Upload Video
            </va-button>
            <label class="ml-4">{{ foodVideoName }}</label>
            <div class="d-flex justify--end mt-3">
              <va-button type="submit" class="my-0 va-button-normal-cancel" @click="list()">Cancel</va-button>
              <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="createFood()">Create</va-button>
              <va-button v-if="isUpdate" type="submit" class="my-0" @click.prevent="updateFood()">Update</va-button>
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>

  import Vue from 'vue'
  import vueResource from 'vue-resource'
  import { search } from '../../i18n/search.js'
  import config from '../../i18n/en.json'
  import REGEX from '../../i18n/regex_pattern'
  Vue.use(vueResource)

  export default {
    name: 'app_setting',
    beforeCreate () {
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.get(config.menu.host + '/food_meta')
        .then(response => {
          loader.hide()
          this.food_list = response.body
          this.food_list.map(function(food){
            food.category = typeof(food.category) == ('string') ? JSON.parse(food.category) : food.category;
            var food_pack = '';
            food.category.map(function(food_cat){
              if(food_pack === ''){
                food_pack = food_cat.category
              }else{
                food_pack = food_pack +', '+ food_cat.category
              }
            })
            food.food_category = food_pack
          })
          this.isshowgrid = true
        }, error => {
          loader.hide()
          if (error && error.body) {
            Vue.notify({ text: error.body, type: 'error' })
          }
        })
    },
    data () {
      return {
        term: null,
        perPage: '10',
        perPageOptions: ['4', '6', '10', '20'],
        food_list: [],
        food_name: '',
        FoodNameErrors: [],
        description: '',
        DescriptionErrors: [],
        category: [],
        categoryArr: [ {id:1,category:"Breakfast"},{id:2,category:'Brunch'},{id:3,category:'Lunch'},{id:4,category:'Snacks'},{id:5,category:'Beverage'},{id:6,category:'Dinner'}],
        CategoryErrors: [],
        type: '',
        typeArr: ['Veg', 'Non-Veg'],
        TypeErrors: [],
        price: '',
        PriceErrors: [],
        image_file: [],
        previewImage: null,
        food_video: [], 
        foodVideoName: '',   
        isshowgrid: true,
        isshowForm: false,
        isCreate: false,
        isUpdate: false,
        showRemoveModal: false,
        oldImage: false,
        image_url: ''
      };
    },
    computed: {
      formReady () {
        return !this.FoodNameErrors.length && 
               !this.DescriptionErrors.length && 
               !this.CategoryErrors.length && 
               !this.TypeErrors.length && 
               !this.PriceErrors.length
      },
      CheckFile_upload () {
        if(this.image_file[0]){
          return true;
        }else{
          Vue.notify({ text: 'Please Upload images', type: 'error' });
          return false;
        };
      },
      fields () {
        return [{
          name: 'name',
          title: 'Food Name',
        },
        {
          name: 'food_category',
          title: 'Category',
        },
        {
          name: 'type',
          title: 'Type',
        },
        {
          name: 'price',
          title: 'Price',
          width: '6%',
        },
        {
          name: '__slot:actions',
          dataClass: 'text-right',
        },
        {
          name: '__slot:action',
          dataClass: 'text-right',
        }]
      },
      filteredData () {
        return search(this.term, this.food_list)
      },
    },
    methods: {
      getFood () {
        const loader = Vue.$loading.show({ width: 40, height: 40 })
        this.$http.get(config.menu.host + '/food_meta')
          .then(response => {
            loader.hide()
            this.food_list = response.body
            this.food_list.map(function(food){
              var food_pack = ''
              food.category.map(function(food_cat){
                if(food_pack === ''){
                  food_pack = food_cat.category
                }else{
                  food_pack = food_pack + ', ' +food_cat.category
                }
              })
              food.food_category = food_pack
            })
            this.isshowgrid = true
          }, error => {
            loader.hide()
            if (error && error.body) {
              Vue.notify({ text: error.body, type: 'error' })
            }
          })
      },
      CheckFile (type) {
        if(this.image_file[0] == undefined || this.image_file.length == 0){
          this.previewImage = null
        }
        if (typeof (type) !== 'undefined') {
          var image_file;
          if(type == 'horizontal'){
            image_file = this.image_file[0]
          }
          if ((type == 'horizontal') && (image_file !== undefined)) {
            var file_name = image_file.name.split('.').pop()
            if ((file_name.toLowerCase() === 'png' || 'jpg' || 'jpeg')){
              const img = new Image()
              let width = '500'
              let height = '500'
              this.image_name = image_file.name
              const reader = new FileReader()
              reader.readAsDataURL(image_file)
              reader.onload = evt => {
                if(type == 'horizontal'){
                  this.previewImage = evt.target.result
                  this.oldImage = false
                }
                img.onload = () => {
                  if (img.width == width && img.height == height) {
                    if(type == 'horizontal'){
                      this.image_name = image_file.name
                    }
                  } 
                  else {
                    if(type === 'horizontal'){
                      this.image_file = ''
                    }
                    var flag = this.image_file ? 'horizontal' : 'banner'
                    if (flag === 'horizontal') {
                      this.image_file = []
                    }
                    if(this.image_file == ''){
                      this.previewImage = null
                    }
                    Vue.notify({ text: 'Please check the image size', type: 'error' })
                  }
                }
                img.src = evt.target.result
              }
            }else {
              if(type === 'horizontal'){
                this.image_file = ''
                Vue.notify({ text: 'Please check the image Format', type: 'error' })
              }
            }
          }
        }  
      },
      validateVideo (eve, type) {
        var video = eve.target.files[0]
        var size = eve.target.size
        if ((size / (1024 * 1024)) <= 200) {
          if (type == 'food') {
            this.foodVideoName= video.name
            this.food_video = video
          }
          return true
        } else {
          alert('please upload video less than 200 MB')
          this.foodVideoName = ''
          return false
        }
      },
      createFood () {
        this.FoodNameErrors = this.food_name ? [] : ['Food Name is required'];
        this.DescriptionErrors = this.description ? [] : ['Description is required'];
        this.CategoryErrors = ((this.category == '') || (this.category.length == 0)) ? ['Category is required'] : [];
        this.TypeErrors = this.type ? [] : ['Type is required'];
        this.PriceErrors = this.price ? [] : ['Price is required'];

        if(!this.formReady) {
          return
        }
        if(!this.CheckFile_upload){
          return
        }
        if(this.price <= 0){
          return Vue.notify({ text: 'Price should not be zero', type: 'error'})
        }

        if (!REGEX.FoodName(this.food_name)) {
          return Vue.notify({ text: 'Food Name is Not valid', type: 'error'})
        }

        var payload = new FormData()
        payload.append('image', this.image_file[0])
        payload.append('image_file_name', this.image_name)
        payload.append('name', this.food_name)
        payload.append('description', this.description)
        payload.append('category', JSON.stringify(this.category))
        payload.append('type', this.type)
        payload.append('price', this.price)
        payload.append('video',this.food_video);
        payload.append('video_name',this.foodVideoName);

        const loader = Vue.$loading.show({ width: 40, height: 40});
        this.$http.post(config.menu.host + '/food_meta', payload).then(response => {
          loader.hide();
          Vue.notify({ text: response.body, type: 'success' });
          this.list();
        },err =>{
          loader.hide();
          if(err && err.body){
            Vue.notify({ text: err.body, type: 'error'});
          };
        });
      },
      updateFood () {
        this.DescriptionErrors = this.description ? [] : ['Description is required'];
        this.CategoryErrors = ((this.category == '') || (this.category.length == 0)) ? ['Category is required'] : [];
        this.TypeErrors = this.type ? [] : ['Type is required'];
        this.PriceErrors = this.price ? [] : ['Price is required'];

        if (!this.formReady) {
          return
        }
        if(this.price <= 0){
          return Vue.notify({ text: 'Price should not be zero', type: 'error'})
        }
        var payload = new FormData()
        payload.append('image',this.image_file[0]);
        payload.append('image_file_name',this.image_name);
        payload.append('name',this.food_name);
        payload.append('description',this.description);
        payload.append('category',JSON.stringify(this.category));
        payload.append('type',this.type);
        payload.append('price',this.price);
        payload.append('video',this.food_video);
        payload.append('video_name',this.foodVideoName);
        payload.append('food_meta_id',this.food_meta_id)


        const loader = Vue.$loading.show({ width: 40, height: 40 });
        this.$http.put(config.menu.host + '/food_meta/' + this.food_meta_id, payload).then(responseData => {
          loader.hide();
          Vue.notify({ text: responseData.body, type: 'success' });
          this.list();
        }, err => {
          loader.hide();
          if (err && err.body) {
            Vue.notify({ text: err.body, type: 'error' });
          };
        });
      },
      edit (row) {
        this.title = 'Update Food';
        this.food_meta_id = row.food_meta_id;
        this.food_name = row.name;
        this.description = row.description;
        this.category = row.category;
        this.type = row.type;
        this.price = row.price;
        this.FoodNameErrors = [];
        this.DescriptionErrors = [];
        this.CategoryErrors = [];
        this.TypeErrors = [];
        this.PriceErrors = [];
        this.image_file = [];
        this.image_url = row.image;
        this.food_video = [];
        this.foodVideoName = '';
        this.previewImage = '';
        this.isshowgrid = false;
        this.isshowForm = true;
        this.isCreate = false;
        this.isUpdate = true;
        this.oldImage = true;
        this.oldvideo = true;
      },
      remove (row) {
        this.msg = 'Are you sure to delete the food ' + row.name + ' ?';
        this.entity = row;
        this.showRemoveModal = true;
      },
      cancel () {
        this.showRemoveModal = false;
      },
      deleteFood (data) {
        this.$http.delete(config.menu.host + '/food_meta/' + data.food_meta_id).then(resp => {
          Vue.notify({ text: resp.body, type: 'success' });
          this.list();
        }, err => {
          if (err && err.body) {
            Vue.notify({ text: err.body, type: 'error' });
          };
        });
      },
      add () {
        this.title = 'Create Food'
        this.food_name = '';
        this.FoodNameErrors = [];
        this.description = '';
        this.DescriptionErrors = [];
        this.category = '';
        this.CategoryErrors = [];
        this.type = '';
        this.TypeErrors = [];
        this.price = '';
        this.PriceErrors = [];
        this.food_video = [];
        this.foodVideoName = '';
        this.image_file = [];
        this.previewImage = '';
        this.isshowForm = true;
        this.isshowgrid = false;
        this.isUpdate = false;
        this.isCreate = true;
      },
      list () {
        this.getFood()
        this.isshowgrid = true
        this.isshowForm = false
      },
      search: function (term) {
        this.term = term
      },
    },
  }
</script>
